import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "./Components/Header/Header";
import "./index.css";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import React, { Suspense, lazy, useEffect, useState } from "react";
import Loader from "./Components/Loader/Loader";
import { useTranslation } from "react-i18next";
import Icons from "./Constants/Icons";
import NewsLetterSubscribe from "./Util/NewsLetterSubscribe";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "./Config/Actions/loadingActions";
import { setLanguage } from "./Config/Actions/LanguageActions";
import i18n from "./Components/LanguageSelector/i18n";
import { initGA, trackPageLoadTime } from "./Components/analytics";
const Dashboard = lazy(() => import("../src/Screens/Dashboard"));
const ContactUs = lazy(() => import("./Screens/ContactUs"));
const AboutUs = lazy(() => import("./Screens/AboutUs"));
const ManageYourProperty = lazy(() => import("./Screens/ManageYourProperty"));
const TermsCondition = lazy(() => import("./Screens/TermsCondition"));
const PrivacyPolicy = lazy(() => import("./Screens/PrivacyPolicy"));
const StaywithUs = lazy(() => import("./Screens/StaywithUs"));
const Faq = lazy(() => import("./Screens/Faq"));
const Team = lazy(() => import("./Screens/Team"));
const Go = lazy(() => import("./Screens/TypeForm/Go"));
function App() {
  const dispatch = useDispatch();
  const [chatraID, setChatraID] = React.useState("nezYTGcz7oEQpNCiH");
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [newsLetterMessage, setNewsletterMessage] = useState("");
  const [prevLocation, setPrevLocation] = useState(location);
  useEffect(() => {
    if (location.pathname === "/go/" || location.pathname === "/go") {
      setChatraID("");
    } else {
      setChatraID("nezYTGcz7oEQpNCiH");
    }
  }, [location.pathname]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleChange = (e) => {
    if (e.target.name == "phone") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.replace(/[^0-9.]/g, ""),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const validateForm = () => {
    let formErrors = {};
    let valid = true;

    if (!formData.name) {
      valid = false;
      formErrors.name = "Name is required.";
    }

    if (!formData.email) {
      valid = false;
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      formErrors.email = "Email is invalid.";
    }

    if (!formData.phone) {
      valid = false;
      formErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      valid = false;
      formErrors.phone = "Phone number is invalid.";
    }

    setErrors(formErrors);
    return valid;
  };

  const onClickSendNewsLetter = async () => {
    if (validateForm()) {
      dispatch(setLoadingTrue());
      try {
        const data = await NewsLetterSubscribe(formData);
        setNewsletterMessage("Thank you for subscribing to our newsletter!");
        localStorage.setItem("lastSubscribed", true);
        dispatch(setLoadingFalse());
        setShowModal(false);
        setSuccess(true);
        setFormData({
          email: "",
          name: "",
          phone: "",
        });
      } catch (error) {
        setNewsletterMessage(error.response.data.message);
        setSuccess(true);
        dispatch(setLoadingFalse());
      }
    }
  };
  const showHeaderFooter =
    location.pathname !== "/go/" && location.pathname !== "/go";
  useEffect(() => {
    if (showHeaderFooter) {
      const lastSubscribed = localStorage.getItem("lastSubscribed");
      if (lastSubscribed != true) {
        const lastShown = localStorage.getItem("modalLastShown");
        const now = new Date().getTime();
        if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) {
          setTimeout(() => {
            setShowModal(true);
            localStorage.setItem("modalLastShown", now.toString());
          }, 15000);
        }
      }
    }
  }, [showHeaderFooter]);
  useEffect(() => {
    if (prevLocation.pathname !== location.pathname) {
      dispatch(setLoadingTrue());
      const timer = setTimeout(() => dispatch(setLoadingFalse()), 3000);
      return () => clearTimeout(timer);
    }
    setPrevLocation(location);
  }, [location, prevLocation]);

  useEffect(() => {
    dispatch(setLoadingTrue());
    const handleDOMContentLoaded = () => {
      setTimeout(() => {
        dispatch(setLoadingFalse());
      }, 5000);
    };
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      handleDOMContentLoaded();
    } else {
      document.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
    }
    return () => {
      document.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
    };
  }, [dispatch]);

  useEffect(() => {
    const parts = location.pathname.split("/").filter(Boolean);
    const lastPart = parts[parts.length - 1];
    if (lastPart === "fr") {
      localStorage.setItem("language", "fr");
      localStorage.setItem("language1", "Français");
      dispatch(setLanguage("Français"));
      i18n.changeLanguage("fr");
    } else if (lastPart === "en") {
      localStorage.setItem("language", "en");
      localStorage.setItem("language1", "English (ca)");
      dispatch(setLanguage("English (ca)"));
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    initGA().then(() => {
      trackPageLoadTime(); 
    });
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <ScrollToTop>
        <Suspense fallback={<div></div>}>
          {showHeaderFooter && <Header />}
          <PageTracker />
          <Routes>
            <Route path="/Team" element={<Team />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/AboutUs/" element={<AboutUs />} />
            <Route
              path="/ManageYourProperty"
              element={<ManageYourProperty />}
            />
            <Route path="/StaywithUs" element={<StaywithUs />} />
            <Route path="/TermsCondition" element={<TermsCondition />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/go/" element={<Go />} />

            <Route path="/AboutUs/fr/" element={<AboutUs />} />
            <Route path="/AboutUs/en/" element={<AboutUs />} />
            <Route path="/Team/fr/" element={<Team />} />
            <Route path="/Team/en/" element={<Team />} />
            <Route path="/ContactUs/fr/" element={<ContactUs />} />
            <Route path="/ContactUs/en/" element={<ContactUs />} />
            <Route path="/Faq/fr/" element={<Faq />} />
            <Route path="/Faq/en/" element={<Faq />} />
            <Route
              path="/ManageYourProperty/fr/"
              element={<ManageYourProperty />}
            />
            <Route
              path="/ManageYourProperty/en/"
              element={<ManageYourProperty />}
            />
            <Route path="/StaywithUs/fr/" element={<StaywithUs />} />
            <Route path="/StaywithUs/en/" element={<StaywithUs />} />
            <Route path="/TermsCondition/fr/" element={<TermsCondition />} />
            <Route path="/TermsCondition/en/" element={<TermsCondition />} />
            <Route path="/PrivacyPolicy/fr/" element={<PrivacyPolicy />} />
            <Route path="/PrivacyPolicy/en/" element={<PrivacyPolicy />} />
            <Route path="/fr/" element={<Dashboard />} />
            <Route path="/en/" element={<Dashboard />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {showHeaderFooter && <Footer />}
        </Suspense>
      </ScrollToTop>
      <Loader />

      {showModal && (
        <div
          style={{ zIndex: 9999, background: " rgba(41, 42, 40, 0.55)" }}
          className="success-popup"
        >
          <div
            style={{
              borderRadius: "10px",
              position: "relative",
              width: "700px",
            }}
            className="success-popup-content Optima "
          >
            <img
              onClick={() => setShowModal(false)}
              style={{
                alignSelf: "flex-end",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                height: "25px",
                width: "25px",
              }}
              src={Icons.ic_orange_close}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <img
                className=" object-contain"
                style={{ height: "50px", width: "50px", alignSelf: "center" }}
                src={Icons.newsletter}
              />
            </div>
            <div
              style={{
                fontWeight: "100",
                color: "#E0C9AA",
                marginBottom: "15px",
              }}
              className="cinzel uppercase"
            >
              {t("SUBSCRIBE TO OUR NEWSLETTER")}
            </div>
            <div className="newsletter_main_container px-[60px] max-[767px]:px-[20px]">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    type="text"
                    name="name"
                    style={{
                      border: "0.5px solid #fff",
                      color: "white",
                    }}
                    placeholder={t("Name")}
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="font-24">{t(errors.name)}</span>
                  )}
                </div>
                

                <div
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    type="email"
                    name="email"
                    style={{
                      border: "0.5px solid #fff",
                      color: "white",
                    }}
                    placeholder={t("Email")}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="font-24">{t(errors.email)}</span>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <input
                    type="tel"
                    name="phone"
                    style={{
                      border: "0.5px solid #fff",
                      color: "white",
                    }}
                    className=" capitalize"
                    placeholder={t("Phone number")}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="font-24">{t(errors.phone)}</span>
                  )}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="Question_grid "
                >
                  <button
                    className=" white_class w-fit"
                    style={{
                      borderRadius: "14px",
                      background: "#E0C9AA",
                      paddingLeft: "60px",
                      paddingRight: "60px",
                      padding: "10px 30px",
                      color: "#000",
                      fontWeight: "100",
                    }}
                    onClick={() => onClickSendNewsLetter()}
                  >
                    {t("Subscribe")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="Success">
          <div className="Success_center">
            <div
              style={{
                color:
                  newsLetterMessage ==
                  "Thank you for subscribing to our newsletter!"
                    ? "#FFF"
                    : "rgb(180 47 47)",
              }}
            >
              {newsLetterMessage}
            </div>
            <div
              onClick={() => setSuccess(false)}
              style={{ padding: "4px" }}
              className="custom-btn btn-14"
            >
              Ok
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const PageTracker = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.gtag) {
      const pageTitle = getPageTitle(location.pathname);
      window.gtag("config", "G-M83YMPYBV3", {
        page_path: location.pathname,
        page_title: pageTitle,
      });
    }
  }, [location]);

  return null;
};

const getPageTitle = (pathname) => {
  switch (pathname) {
    case "/":
      return "Home Page";
    case "/Team":
      return "Team";
    case "/ContactUs":
      return "Contact Us";
    case "/Faq":
      return "Faq";
    case "/AboutUs/":
      return "About Us";
    case "/AboutUs":
      return "About Us";
    case "/ManageYourProperty":
      return "Manage Your Property";
    case "/StaywithUs":
      return "Stay With Us";
    case "/TermsCondition":
      return "Terms & Condition";
    case "/PrivacyPolicy":
      return "Privacy Policy";
    case "/go":
      return "Go";
    case "/AboutUs/fr/":
      return "About Us French";
    case "/AboutUs/en/":
      return "About Us English";
    case "/Team/fr/":
      return "Team French";
    case "/Team/en/":
      return "Team English";
    case "/ContactUs/fr/":
      return "ContactUs French";
    case "/ContactUs/en/":
      return "ContactUs English";
    case "/Faq/fr/":
      return "Faq French";
    case "/Faq/en/":
      return "Faq English";
    case "/ManageYourProperty/fr/":
      return "Manage Your Property French";
    case "/ManageYourProperty/en/":
      return "Manage Your Property English";
    case "/StaywithUs/fr/":
      return "Staywith Us French";
    case "/StaywithUs/en/":
      return "Stay With Us English";
    case "/TermsCondition/fr/":
      return "Terms & Condition French";
    case "/TermsCondition/en/":
      return "Terms & Condition English";
    case "/PrivacyPolicy/fr/":
      return "Privacy Policy French";
    case "/PrivacyPolicy/en/":
      return "Privacy Policy English";
    case "/fr/":
      return "Home Page French";
    case "/en/":
      return "Home Page English";
    default:
      return "Page";
  }
};

export default App;
